import axios from "@/axios";
import httpStatusCode from "http-status-codes";

export default {
  fetchMerchantDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/merchantDetails/" + id)
        .then(res => {
          if (res.data.data) {
            commit("SET_CUSTOMER_MERCHANT", res.data.data);
            localStorage.setItem("merchant", JSON.stringify(res.data.data));
          }

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendCustomerOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/sendOtpCode", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  submitPaymentRequestId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/verifyPaymentRequestId", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMerchantLogo({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/getMerchantLogo/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  submitOtpAndLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/submitOtp", data)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            localStorage.setItem(
              "customer",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem(
              "accessToken",
              response.data.data.tokens.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.tokens.refreshToken
            );
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.tokens.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateCustomerPaymentMethod({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/updatePaymentMethod", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  verifyPRDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/verify-pr-details", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  verifyAccountOtp({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/verify-account-otp", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  verifyAccountEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/verify-account-email/${data.token}`)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  updateNewPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/create-password`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendCustomerCreateOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-customer-create-otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendCustomerCreateEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-customer-create-email`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/login`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  submitOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
};
