import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleAdmin from "./admin/moduleAdmin.js";
import moduleHistory from "./history/moduleHistory";
import moduleAuth from "./auth/moduleAuth.js";
import moduleUser from "./user-management/moduleUserManagement.js";
import moduleTransaction from "./transaction/moduleTransaction.js";
import modulePayment from "./payment/modulePayment";
import modulePaymentRequest from "./paymentRequest/modulePaymentRequest";
import moduleBank from "./bank/moduleBank";
import modulePartner from "./partner/modulePartner";
import moduleCustomField from "./customField/moduleCustomField";
import legalModule from "./legals/moduleLegal";
import paymentPlanModule from "./paymentplans/modulePaymentPlans";
import customerModule from "./customers/moduleCustomer";
import listSettingsModule from "./merchantSettings/moduleListSetting";
import merchantGlobalSettingModule from "./merchantGlobalSetting/merchantGlobalSetting";
import logoModule from "./logo/logo";
import merchantSettings from "./merchantSettings/moduleListSetting";
import partnerDashboard from "./partnerDashboard/moduleDashboardRequest";
import communicationGlobalSetting from "./communicationGlobalSetting/moduleCommunicationGlobalSetting";
import customerPortalSetting from "./customerPortalSetting/customerPortalSetting";
import brandSetting from "./brandSetting/brandSetting";
import paymentRequestTemplate from "./paymentRequestTemplate/paymentRequestTemplate";
import linkRequestModule from "./linkRequest/moduleLinkRequest";
import paymentCardOptionModule from "./paymentCardOptions/modulePaymentCardOption";
import s3Service from "./s3Service/moduleS3Service";
import moduleApiLog from "./apiLog/moduleApiLog";
import savedExternalAccount from "./savedExternalAccounts/moduleSavedExternalAccoounts";
import introducerModule from "./introducer/moduleIntroducer";
import moduleSetting from "./setting/moduleSetting";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    admin: moduleAdmin,
    user: moduleUser,
    paymentRequest: modulePaymentRequest,
    transaction: moduleTransaction,
    payment: modulePayment,
    bank: moduleBank,
    partner: modulePartner,
    customField: moduleCustomField,
    legals: legalModule,
    paymentPlans: paymentPlanModule,
    customer: customerModule,
    listSetting: listSettingsModule,
    merchantGlobalSetting: merchantGlobalSettingModule,
    logo: logoModule,
    merchantSettings: merchantSettings,
    partnerDashboard: partnerDashboard,
    communicationGlobalSetting: communicationGlobalSetting,
    customerPortalSetting: customerPortalSetting,
    brandSetting: brandSetting,
    paymentRequestTemplate: paymentRequestTemplate,
    history: moduleHistory,
    linkRequest: linkRequestModule,
    paymentCardOption: paymentCardOptionModule,
    s3ServiceModule: s3Service,
    apiLog: moduleApiLog,
    savedExternalAccount: savedExternalAccount,
    introducer: introducerModule,
    setting: moduleSetting,
  },
  strict: process.env.NODE_ENV !== "production",
});

