import axios from "@/axios";


export default {

  fetchSettingsByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get('defaultCompanyHeader/merchant')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBrandingSettingsByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`defaultCompanyHeader/${merchantId}/merchant`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveDefaultCompanyHeader({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`defaultCompanyHeader`, data)
        .then(response => {
          resolve(response);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  fetchListSettingByPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`listSetting/${data.partnerId}/?name=${data.name}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveListSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("listSetting/", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updatelistSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`listSetting/${data.partnerId}/?name=${data.name}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  // fonts and brand
    fetchDefaultBrandByMerchantId({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('brandSettings/fetchDefaultBranding')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBrandByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`brandSettings/${merchantId}/fetchSettings`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchNotification({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('merchantSettings/notification')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchGlobalMerchantSettings({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`communicationSettings/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

};
