import axios from "@/axios";

export default {
  fetchMerchantCommunicationSettingsByPartner({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`communicationSettings/${id}`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  updateMerchantCommunicationSettingsByPartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`communicationSettings/${data.id}`, data.payload).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },
};
