/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  getPaymentDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/payment/details?paymentRequestId=" + id).then(res => {
        if (res.data.data) {
          commit("SET_PAYMENT_PAGE_HEADER", res.data.data);
        }
        return resolve(res);
      }).catch(err => {
        return reject(err);
      });
    });
  },

  makePayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/make", data.obj, data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  makeStaticPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/makeStatic", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  getQuotationDataForPayLater({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/calculation", data)
        .then(res => {

          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendConfirmation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/confirmation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendFailedConfirmation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/failedConfirmation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendDeviceVerificationCodeForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/device/sendCode", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyDeviceForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/device/verify", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendLoginOTPCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/customers/sendOtpCode", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyLoginOTPCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/customers/submitOtp", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyIDMatrix({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/payment/verifyID/${data.id}`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/login`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  submitOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  sendEmailVerificationCodeForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/email/sendCode", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyEmailForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/email/verify", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  getProductCards({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/productCard" ).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  updateRequiredFields({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/payment/requiredFields/${payload.paymentRequestId}`, payload.data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  setSelectedRequestType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/payment/setSelectedRequestType/${payload.paymentRequestId}`, payload.data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  updateDisbursement({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`/payment/disbursement/${payload.paymentRequestId}`, payload.data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },
};

