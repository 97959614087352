import state from "./moduleSavedExternalAccountsState";
import mutations from "./moduleSavedExternalAccountsMutations";
import actions from "./moduleSavedExternalAccountsActions";
import getters from "./moduleSavedExternalAccountsGetters";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
