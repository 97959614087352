import axios from "@/axios";

export default {
  upsertLegal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("admin/legals", data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchAllLegals({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("/admin/legals/" + params).then(res => {
        commit("SET_LEGALS", res.data.data);
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchLegalWithId({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`/admin/legals/${params.id}/${params.type}`).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchTermsAndConditions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("customers/terms-and-conditions").then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchLegalsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/admin/legals/id/" + id).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchProductLegalsById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get("/paymentPlans/product-legals/" + data.id + "/" + (data.customer || "")).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  getPayLaterTerm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get("admin/payment-plans/getPlanById/" + data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },
};

