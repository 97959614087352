
export const auth = (to, from, next) => {
  const token = localStorage.getItem("accessToken");
  const publicPages = [
    "/login",
    "/forgot-password",
    "/verify-email/" + to.params.token,
    "/reset-password/" + to.params.token,
    "/pages/error-419",
    "/admin/login",
    "/success"
  ];
  const authRequired = !publicPages.includes(to.path);

  if (to.path === "/login" || to.path === "/admin/login") {
    if (token) {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      return next(user.role === "partner" ? "dashboard" : "/admin/dashboard");
    }
  }

  if (!authRequired && token) {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    return next("/admin/login");
  }

  if (authRequired && !token && to.name !== "user-reset-password") {
    if (from.path !== "/admin/login") {
      const firstPath = to.path.split("/")[1];

      return next(firstPath === "admin" ? "/admin/login" : "/login");
    }
  }

  return next();
};
