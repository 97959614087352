import state from "./modulePaymentCardOptionState";
import mutations from "./modulePaymentCardOptionMutations";
import actions from "./modulePaymentCardOptionActions";
import getters from "./modulePaymentCardOptionGetters";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
