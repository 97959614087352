import axios from "@/axios";

export default {
  fetchIntroducersWithLinkRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/introducers/${id}/introducers`).then((res) => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      })
    })
  },

  fetchIntroducerMerchantLink({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`/introducers/${data.merchantId}/${data.introducerId}`).then((res) => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      })
    })
  },
}

