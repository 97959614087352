import axios from "@/axios";

export default {

  // fetch by merchant id
  fetchLinkRequestByMerchantId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/linkRequest/' + id + '/all').then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },

  // fetch link request detail
  fetchRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/linkRequest/' + id).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  },

  //update status
  updateLinkRequestById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`linkRequest/${data.id}/updateStatus`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createIntroducerLinkRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("linkRequest/linkIntroducerToMerchant", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((ex) => {
          return reject(ex.response);
        });
    });
  },

}
