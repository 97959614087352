import axios from "@/axios.js";

export default {
  fetchSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`settings/fetch/${payload}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchBoxAndDiceProduct({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post("settings/boxAndDiceProduct").then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  }
};


