import Vue from "vue";
import App from "./App.vue";
import { differenceInYears } from "date-fns";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import lodashHelper from './helper/lodashUtility';
// axios
import axios from "./axios.js";
// API Calls
import "./http/requests";

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

// default axios

import axiosdefault from "axios";
// VeeValidate
import VeeValidate from "vee-validate";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";

import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

Vue.use(Vuesax);

import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);

// Sentry logging
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_SENTRY_ORIGINS, /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_RATE),
  ignoreErrors: [
    'Error',
    /^401$/,
    /^410$/,
  ],
});

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

Vue.prototype.$http = axios;

VeeValidate.setMode("eager");

// validator checks if age is 18 years

VeeValidate.Validator.extend("18-years-age", {
  getMessage: (_field) => "Must be over 18 to proceed",
  validate: (value) => {
    if (differenceInYears(new Date(), new Date(value)) >= 18) {
      return true;
    }
    return false;
  },
});

// custom directive to make button rounded

Vue.directive("round", {
  bind(el, binding, vnode) {
    el.style.borderRadius = "30px";
  },
});

VeeValidate.Validator.localize("en", {
  custom: {
    password: {
      regex:
        "Password must contain at least 8 characters including one uppercase letter,one lowercase letter and one number.",
    },
  },
});
const lodashHelperPlugin = {
  install () {
    Vue.lodashHelper = lodashHelper
    Vue.prototype.$lodashHelper = lodashHelper
  }
}

Vue.use(lodashHelperPlugin)


// formats currency as $123,56.67
Vue.mixin({
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
    formatNumber(value) {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    },
    replaceLegalContent(str, mix) {
      const RGX = /{{(.*?)}}/g;
      return str.replace(RGX, (x, key, y) => {
        x = 0;
        y = mix;
        key = key.trim().split(".");
        while (y && x < key.length) {
          y = y[key[x++]];
        }
        return y != null ? y : "";
      });
    },
    showToastMessage(title = "Success", message = "Success", type = "success") {
      let toastDetail = {};
      const icon = {
        iconClass: "",
        iconChildren: "",
        iconTag: "span"
      };
      toastDetail.position = "top-right";
      toastDetail.timeout = 5000;
      toastDetail.closeOnClick = false;
      toastDetail.pauseOnFocusLoss = false;
      toastDetail.pauseOnHover = false;
      toastDetail.draggable = false;
      toastDetail.draggablePercent = 0.1;
      toastDetail.showCloseButtonOnHover = false;
      toastDetail.hideProgressBar = true;
      toastDetail.closeButton = "button";
      toastDetail.icon = icon;
      toastDetail.rtl = false;
      toastDetail.message = message;

      if (type == "success") {
        toastDetail.icon.iconClass = "material-icons text-success";
        toastDetail.icon.iconChildren = "check_circle_outline";
        this.$toast.success(<div class="container"><h3>{title}</h3><p class="empty:hidden">{message}</p></div>, toastDetail);
      } else if (type == "warning") {
        toastDetail.timeout = false;
        toastDetail.icon.iconClass = "material-icons text-warning";
        toastDetail.icon.iconChildren = "information_outline";
        this.$toast.warning(<div class="container"><h3>{title}</h3><p class="empty:hidden">{message}</p></div>, toastDetail);
      } else {
        toastDetail.timeout = false;
        toastDetail.icon.iconClass = "material-icons text-danger";
        toastDetail.icon.iconChildren = "highlight_off";
        this.$toast.error(<div class="container"><h3>{title}</h3><p class="empty:hidden">{message}</p></div>, toastDetail);
      }
    },
  },
  computed: {
    isFormDirty() {
      return Object.keys(this.fields).some((key) => this.fields[key].dirty);
    },
    validateForm() {
      return !this.errors.any();
    },
  },
});

Vue.use(VeeValidate, {
  classes: true,
});


Vue.use(VueHammer);
// Vue.use(Donut);
// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 30,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.message === toast.message
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
});

new Vue({
  router,
  store,
  i18n,
  watch: {
    $route: {
      handler(to, from){
        if ( to.name !== from.name ) {
          if ( localStorage.getItem('formFieldUpdated')
            && localStorage.getItem('formFieldUpdated') === 'yes'
          ) {
            localStorage.setItem('formFieldUpdated', 'no')
          }
          if ( localStorage.getItem('redirectTo')
            && localStorage.getItem('redirectTo') !== 'partner-login'
          ) {
            localStorage.setItem('redirectTo', undefined)
          }
        }
      },
      flush: 'post',
    }
  },
  render: (h) => h(App),
}).$mount("#app");
