/*=========================================================================================
  File Name: moduleBankActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  init() {
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem("accessToken");
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (!error.response) {
          ;
          return Promise.reject({
            message: "Please check your internet connection."
          });
        }
        const {
          config,
          response: { status }
        } = error;

        if (config.url.includes("login") && status === 401) {
          return Promise.reject({ message: error.response.data.message });
        }

        if (config.url.includes("refreshToken") && status === 401) {
          if (config.url.includes("/admin/")) {
            router.push("/admin/login");
          } else {
            router.push("/login");
          }
        }

        const originalRequest = config;
        if (status === 401) {
          // if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            const refreshToken = refreshToken;
            isAlreadyFetchingAccessToken = true;
            store.dispatch("auth/fetchAccessToken").then(refreshToken => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(refreshToken);
            });
          }

          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = access_token;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  },

  fetchBankByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("banks/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBanksAccountByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("banks/fetchBanksAccountByPartnerId/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateDisplayNameByPartnerId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("banks/updateDisplayNameByPartnerId",payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

};
