import state from "./moduleIntroducerState.js";
import mutations from "./moduleIntroducerMutations.js";
import actions from "./moduleIntroducerActions.js";
import getters from "./moduleIntroducerGetters.js";

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}