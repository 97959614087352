/*=========================================================================================
  File Name: modulePaymentRequestActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {

  fetchOnboardDetailByOnboardId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/onboarding/detail/" + id).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchOnboardDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("onboarding/detail/review/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchOnboardDetailWithMerchantId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`onboarding/review/${data.onboardingId}/${data.merchantId}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchLegalDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("admin/legals/id/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchCustomerPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("customers/paymentRequests/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchCustomerPaymentRequestDetailWithPartnerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("customers/paymentRequestDetails/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.delete(`paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  cancelPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put("paymentRequests/cancel/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  resendPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put("paymentRequests/resend/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchCustomerPaymentRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("customers/paymentRequests/list" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
};

