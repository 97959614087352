<template functional>
  <div class="vx-breadcrumb" :class="data.staticClass">
    <ul class="flex flex-wrap items-center">
      <li
        v-for="(link, index) in props.route.meta.breadcrumb"
        :key="index"
        class="inline-flex items-center"
      >
        <router-link :to="link.url" v-if="link.url">{{
          link.title
        }}</router-link>
        <span class=" cursor-default" v-else>{{ link.title }}</span>

        <span class="breadcrumb-separator mx-2 flex items-start" v-if="link.url">
          <vs-icon icon="chevron_right" size="small" color="#757575" ></vs-icon>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "vx-breadcrumb",
  props: {
    route: {
      type: Object,
      required: true
    },
  },

  computed: {
    breadcrumb() {
      return this.data;
    },
    activePage() {
      return this.data;
    }
  }
};
</script>
