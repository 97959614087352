/*=========================================================================================
  File Name: modulePaymentRequestActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  storePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("paymentRequests", data.obj, data.config).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  storeNewPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("paymentRequests", data.obj, data.config).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchPaymentRequestListByReqTemId({ commit }, reqTemId) {
    return new Promise((resolve, reject) => {
      axios.get("paymentRequests/paymentRequestTemplate/" + reqTemId).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchPaymentRequestListByPartnerIdWithFilter({ commit }, data) {
    const searchParam = data.searchParam;
    const partnerId = data.partnerId;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchParam).toString();

      axios.get("paymentRequests/partnerWithFilter/" + partnerId + "?" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchPaymentRequestCountByPartnerIdWithFilter({ commit }, data) {
    const searchparam = data.searchParam;
    const partnerId = data.partnerId;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchparam).toString();

      axios.get("paymentRequests/partnerWithFilter/totalCount/" + partnerId + "?" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchDisbursementListByPartnerIdWithFilter({ commit }, data) {
    const searchParam = data.searchParam;
    const partnerId = data.partnerId;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchParam).toString();

      axios.get("paymentRequests/disbursementWithFilter/" + partnerId + "?" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchDisbursementCountByPartnerIdWithFilter({ commit }, data) {
    const searchparam = data.searchParam;
    const partnerId = data.partnerId;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchparam).toString();

      axios.get("paymentRequests/disbursementWithFilter/totalCount/" + partnerId + "?" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  exportPaymentRequestWithFilter({ commit }, data) {
    const searchparam = data.searchParam;
    const partnerId = data.partnerId;
    const type = data.type;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchparam).toString();

      axios.get("paymentRequests/exportPaymentRequest/" + type + "/" + partnerId + "?" + params, {
        responseType: "blob"
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        // Extract filename from the content-disposition header
        const contentDisposition = res.headers["content-disposition"];
        let fileName = "report.xlsx";

        if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);

          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("paymentRequests/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchCustomerPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("customers/paymentRequests/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
  fetchCustomerPaymentRequestDetailWithPartnerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("customers/paymentRequestDetails/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.delete(`paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  cancelPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put("paymentRequests/cancel/" + data.id, data.data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  resendPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put("paymentRequests/resend/" + data.id, data.data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchCustomerPaymentRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("customers/paymentRequests/list" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchAmountOrValueByStatusAndDate({ commit }, params) {

    return new Promise((resolve, reject) => {
      axios.get("paymentRequests/transcByCard" + params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  calculateOnPayLater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("calculation", data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  getPlanByCustomPlanId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`paymentPlans/${data}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  getPlanByMerchantAndCustomPlanId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`paymentPlans/${data.merchantId}/${data.customPlanId}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  // saveProductFieldInPr
  saveProductFieldInPr({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("paymentRequests/saveProductFieldInPr", data.data, data.config).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  registerBankTransferPost({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`paymentRequests/registerBankTransfer/${data.id}`, data.data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  getPaymentRequestGroupedTotalAmount({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios.get(`paymentRequests/getTotalActivePaymentRequestAmount/${merchantId}`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  acceptFranchisorPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("paymentRequests/accept-franchisor-PR/" + data.id, data.obj).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  updatePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("/paymentRequests/changePrStatus", data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  getPaymentRequestEmail({ commit }, emailLogId) {
    return new Promise((resolve, reject) => {
      axios.get(`paymentRequests/email-log/${emailLogId}`).then((res) => {
        return resolve(res);
      }).catch((ex) => {
        return reject(ex.response);
      });
    });
  },

  requestTopUp({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("paymentRequests/topUp", data.obj, data.config).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  cancelTopUp({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.patch(`paymentRequests/topUp/cancel/${id}`).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  topUpFileUpload({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("paymentRequests/topUp/fileUpload", data.obj, data.config).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  getStatement({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("paymentRequests/getStatement/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  sendRemittanceEmail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("paymentRequests/sendRemittanceEmail/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  editDisbursementDate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("paymentRequests/updateDisbursementDate", data).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  updatePaymentRequestDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put("paymentRequests/updatePaymentRequest/" + data.id, data.data).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },
};

