import axios from "@/axios";
import jwt from "../../http/requests/auth/jwt/index.js";
import httpStatusCode from 'http-status-codes';

export default {
  changePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt.changePassword(data.userDetails.currentPassword, data.userDetails.newPassword).then(response => {
        // If there's user data in response
        if (response.status == httpStatusCode.OK) {
          resolve(response);
        } else {
          reject({ message: response.data.message });
        }
      }).catch(({ response }) => {
        reject({ message: response.data.message });
      });
    })
  },

  logoutAdmin({ commit }) {
    return new Promise((resolve, reject) => {
      jwt.logout().then((response) => {
        if (response.status == httpStatusCode.OK) {
          resolve(response);
        } else {
          reject({ message: response.data.message });
        }
      }).catch(({ response }) => {
        reject({ message: response.data.message });
      });
    })
  },

  checkLogin({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post("auth/check-login").then((response) => {
        resolve(response);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },
}

