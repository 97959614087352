import axios from "@/axios.js"

export default {
  updateAdminProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post("admin/updateprofile", user).then((response) => {
        commit("UPDATE_USER_INFO", response.data.data, {
          root: true,
        });
        resolve(response)
      }).catch((ex) => { reject(ex) });
    });
  },

  updateAdminImage({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/changeProfileImage`, data.obj, data.config, { timeout: 500000 }).then((response) => {
        resolve(response);
      }).catch((ex) => {
        return reject(ex);
      });
    });
  }
}

