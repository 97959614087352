import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default {
  fetchStaffByPartnerId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/partners/staff-list/" + id).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchAllUsers({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/partners/users/" + id).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchStaffDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/partners/staff/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  getThirdPartyToken({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`partners/getToken/${id}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  sendCredentialsToStaff({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/partners/staff/sendCredentials/${id}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  updateStaffDetailsById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("/partners/staff/" + data.id, data.data, data.config).then(res => {
        const user = JSON.parse(localStorage.getItem("userInfo"));

        if (res.data.data.userType === "admin" && user._id === res.data.data._id) {
          commit("UPDATE_USER_INFO", res.data.data, { root: true });
        }

        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  createStaff({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("partners/staff", data.data, data.config).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  deleteStaff({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete("/partners/staff/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  fetchPartnerDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/partners/" + id).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  getPaymentPlanByMerchantId({commit},data){
    const partnerId = data;
    return new Promise((resolve, reject)=>{
      axios.get(`/admin/partners/${partnerId}/plan`).then((response) => {
        resolve(response);
      }).catch((ex) => {
        reject(ex);
      });
    })
  },

  resetThirdPartyToken({commit},data){
    const partnerId = data;
    return new Promise((resolve,reject)=>{
      axios.get(`partners/resetUserToken/${partnerId}`).then((response) => {
        resolve(response);
      }).catch((ex) => {
        reject(ex)
      });
    });
  },

  updateTestSettingStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/partners/update-test-setting-status", data).then((response) => {
        resolve(response);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

  getPayLaterProductsByPartnerId({commit},data){
    const partnerId = data;
    return new Promise((resolve, reject)=>{
      axios.get(`/partners/${partnerId}/paylaterProducts`).then((response) => {
        resolve(response);
      }).catch((ex) => {
        reject(ex);
      });
    })
  },

  submitQuickSetup({commit},data){
    return new Promise((resolve, reject)=>{
      axios.post(`/partners/${data.partnerId}/submitQuickSetup`, data.data, data.config).then((response) => {
        resolve(response)
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  verifySetupToken ({ commit }, token){
    return new Promise((resolve, reject) => {
      axios.post("partners/verifySetupToken/", token).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  sendOtpCodeToMerchant ({ commit }, data){
    return new Promise((resolve, reject) => {
      axios.post("partners/sendOtpCodeToMerchant/", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  verifyOtpCodeOfMerchant ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("partners/verifyOtpCode/", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  createLoginAccount ({ commit }, data){
    return new Promise((resolve, reject) => {
      axios.post("partners/createLoginAccount/", data).then((response) => {
        if (response.data.data) {
          localStorage.setItem("user", JSON.stringify(response.data.data));

          // Set accessToken and refresh token
          localStorage.setItem("accessToken", response.data.data.token.accessToken);
          localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
          localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
          localStorage.setItem("loggedIn", "true");

          // Update user details
          commit("UPDATE_USER_INFO", response.data.data, { root: true });

          // Set bearer token in axios
          commit("SET_BEARER", response.data.data.token.accessToken);

          resolve(response);
        } else {
          reject({ message: response.data.message });
        }

      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  fetchAutomatcherAccount ({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios.get(`partners/automatcher/${partnerId}`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  fetchPartnerContactDetails ({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios.get(`partners/${partnerId}/contact-details`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  updateContactDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("/partners/update-contact-details", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    })
  },

  updateEftOption({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch(`partners/update-eft-option/${data.id}`, data.data).then((response) => {
        resolve(response);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  fetchPartnerStatement({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("partners/getStatement", data).then((response) => {
        resolve(response);
      }).catch((ex) => {
        return reject(ex.response);
      });
    });
  },

  fetchPartnerStaff({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/partners/${id}/staffs`).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchApiUsersByPartnerId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/partners/apiUserList/" + id).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  fetchApiUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/partners/apiUserDetail/" + id).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  generateApiToken({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/partners/${id}/generateApiToken`).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  testBoxAndDiceConnection({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`partners/${data.partnerId}/test-connection`, data.payload).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  updateBoxAndDiceSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch(`partners/${data.merchantId}/editBoxAndDiceSettings`, data).then((res) => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },

  refreshBoxAndDiceSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch(`partners/${data.introducerId}/${data.merchantId}/refreshBoxAndDiceSettings`, data).then((res) => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },
};

