import axios from "@/axios";


export default {

  fetchRequestTemplates({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  createRequestTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`requestTemplate/create`, data.data, data.config)
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          return reject(ex.response);
        });
    });
  },

  fetchRequestTemplateById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${id}/fetch`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateByIdOnMerchant({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${id}/auth`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateForRequestForm({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`requestTemplate/${id}/requestForm`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  fetchRequestTemplateByWithCards({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${id}/fetchWithCards`)
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          return reject(ex.response);
        });
    });
  },



  fetchRequestTemplateByMerchantId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${data.merchantId}/${data.type}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAssignedRequestTemplateByMerchantId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`requestTemplate/assigned/${data.merchantId}/${data.type}`).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  updateRequestTemplateById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`requestTemplate/${data.id}/updateStatus`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  deleteRequestTemplateById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`requestTemplate/${data.id}/delete`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateByIdandStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/byStatus/${data.templateId}/${data.status}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateDisplayOnDashboard({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("requestTemplate/updateDisplayOnDashboard", data).then((res) => {
        resolve(res);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },
};

